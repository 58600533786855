<template>
  <div class="app-context document">
    <!-- <app-header :headerOption="headerOption" ref="appheader"></app-header> -->
    <div class="context" v-if="!isMobile">
      <!--<div class="r-notice">
        <div class="notice">
          <p>땡큐서울이비인후과의원으로 의뢰해 주셔서 감사합니다.</p>
          <p>아래 항목을 입력해 주시기 바랍니다.</p>
        </div>
      </div>-->
      <div class="request-form" style="top: -50px">
        <div class="r-header">
          <div class="logo-wrap">
            <img src="@/assets/images/logo_default.svg" />
          </div>
        </div>
        <div class="r-context">
          <div class="r-top">
            <div class="subject">
              <h3>진료의뢰서</h3>
            </div>
          </div>
          <div class="r-info">
            <div class="article">
              <h5 class="a-title">수신병원</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span class="viewer" v-text="form.receive.value"></span>
                </div>
              </div>
            </div>
            <div class="article">
              <h5 class="a-title">진료과</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span class="viewer" v-text="form.subject.value"></span>
                </div>
              </div>
            </div>
            <div class="article">
              <h5 class="a-title">의사명</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span class="viewer" v-text="form.doctor.value"></span>
                </div>
              </div>
            </div>
            <div class="article">
              <h5 class="a-title">환자명</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span
                    class="viewer"
                    v-text="
                      this.form.agreeYn.value === 'Y'
                        ? form.name.value
                        : form.name.value[0] + '#' + form.name.value.substr(2)
                    "
                  ></span>
                </div>
              </div>
            </div>
            <div class="article pat-jumin">
              <h5 class="a-title">주민등록번호</h5>
              <div class="a-content">
                <div class="input-complex">
                  <div class="input-wrap readonly">
                    <span class="viewer" v-text="form.birth.value"></span>
                  </div>
                  <span class="separated"></span>
                  <div class="input-wrap last readonly">
                    <span class="viewer" v-text="form.gender.value"></span>
                  </div>
                  <span>XXXXXX</span>
                </div>
              </div>
            </div>
            <div class="article pat-phone">
              <h5 class="a-title">연락처</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span
                    class="viewer"
                    v-text="
                      this.form.agreeYn.value === 'Y'
                        ? form.phone.value
                        : phoneMasking(form.phone.value)
                    "
                  ></span>
                </div>
                <div class="trans-agree">
                  <p class="custom">
                    {{
                      this.form.agreeYn.value === "Y"
                        ? "환자가 정보제공 동의를 하였습니다. 예약 상담 전화를 기다릴 수 있으므로, 전화해 주시기 바랍니다."
                        : "환자가 정보제공 동의 절차를 거쳐 동의를 하면, 이름과 연락처가 제대로 표시됩니다."
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="r-info after">
            <div class="article diagNm">
              <h5 class="a-title">진단명 또는 주증상</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span class="viewer" v-text="form.diagnosis.value"></span>
                </div>
              </div>
            </div>
            <div class="article">
              <h5 class="a-title">진단코드</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span class="viewer" v-text="form.code.value"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="diag-detail">
            <div class="item">
              <p class="title">환자 상태 또는 의뢰 내용</p>
              <div class="textarea-wrap">
                <textarea v-model="form.vital.value" readonly />
              </div>
              <div class="attachment-wrap">
                <p class="title">파일명:</p>
                <div class="attachment">
                  <div
                    class="input-wrap"
                    @click="download()"
                    :class="{
                      active: this.form.attachment.filename !== null,
                    }"
                  >
                    <span
                      class="file-name"
                      v-text="form.attachment.filename"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <p class="title">회신내용</p>
              <div class="textarea-wrap">
                <textarea
                  v-model="form.rcvConts.value"
                  readonly
                  v-if="this.isOwn"
                />
                <textarea
                  v-model="form.rcvConts.value"
                  v-else
                  @keyup.enter="focusControl()"
                />
              </div>
              <div class="attachment-wrap">
                <p class="title">파일명:</p>
                <div class="attachment_rcv">
                  <div class="input-wrap">
                    <div
                      v-for="(data, index) in this.form.rcvAttachment"
                      v-bind:key="index"
                      @click="download_rcvFile(data)"
                      :class="{
                        active: data !== null,
                      }"
                    >
                      <div
                        style="border : 2px solid #146f83;"
                        v-if="data.fileNm !== undefined"
                      >
                        <span class="file-name">
                          {{ data.fileNm }}
                        </span>
                        <img
                          style="width : 535px; height : 400px; object-fit: contain;"
                          :src="
                            'https://www.drrefer.net/api/pic/' + data.fileId
                          "
                          v-if="
                            data.fileNm.substring(
                              data.fileNm.lastIndexOf('.')
                            ) === '.png' ||
                              data.fileNm.substring(
                                data.fileNm.lastIndexOf('.')
                              ) === '.jpg'
                          "
                        />
                      </div>
                      <div
                        style="border : 2px solid #146f83;"
                        v-if="data.name !== undefined"
                      >
                        <span class="file-name">
                          {{ data.name }}
                        </span>
                        <img
                          style="width : 535px; height : 400px; object-fit: contain;"
                          :src="data.url"
                          v-if="
                            data.name.substring(data.name.lastIndexOf('.')) ===
                              '.png' ||
                              data.name.substring(
                                data.name.lastIndexOf('.')
                              ) === '.jpg'
                          "
                        />
                      </div>
                      <div style="height : 10px"></div>
                    </div>
                    <input
                      class="hidden file-upload"
                      type="file"
                      @click.stop
                      @change="onFileChange($event)"
                      multiple
                    />
                  </div>
                  <div
                    class="a-btn-wrap"
                    style="height : 60px; width : 240px"
                    v-if="!this.isOwn"
                  >
                    <tr>
                      <td>
                        <button
                          type="button"
                          @click="attachmentFile()"
                          style="width : 100px"
                        >
                          파일첨부
                        </button>
                      </td>
                      <td>
                        <button
                          class="delete"
                          v-if="this.form.rcvAttachment.length !== 0"
                          type="button"
                          @click="deleteFile()"
                          style="width : 100px"
                        >
                          삭제
                        </button>
                      </td>
                    </tr>
                    <tr
                      style="font-size : 11px; width : 100%; position:relative; top : 10px"
                    >
                      <td>
                        문서, 사진, 텍스트등
                      </td>
                      <td>
                        파일을 첨부가능 합니다.
                      </td>
                    </tr>
                  </div>
                </div>
              </div>

              <!--<div class="attachment-wrap">
                <input
                  type="file"
                  id="files"
                  name="files"
                  @change="onFileChange($event)"
                  multiple
                /><br /><br />
                <div v-for="data in this.form.rcvAttachment">
                  <span class="file-name" v-if="data.fileNm !== ''">
                    {{ data.fileNm }}
                  </span>
                  <span class="file-name" v-if="data.name !== ''">
                    {{ data.name }}
                  </span>
                </div>
              </div>-->
            </div>
          </div>
          <!--<div class="agree-info">
            <h4>진료정보제공 동의서</h4>
            <!--
            <p class="paragraph">
              본인은
              <b>{{ form.receive.value }}</b
              >에서 받은 진료 정보를 진료를 의뢰한<br />
              의료인이 진료 목적으로 요청한 경우에 이를 제공하는 것에 동의합니다
            </p>
            <p class="paragraph">
              의뢰서 수신병원에 예약 등 편의를 위해 환자 연락처(전화번호)를
              공유하는 데 동의합니다
            </p>
            -->
          <!--<div class="patient">
              <div
                class="article"
                v-if="
                  agreeResult.rltnTypeNm === '' ||
                  agreeResult.rltnTypeNm === '본인'
                "
              >
                <p class="title">환자:</p>
                <div class="content">
                  <span class="viewer">{{ form.name.value }}</span>
                </div>
              </div>
              <div class="article" v-else>
                <p class="title">법정대리인:</p>
                <div class="content">
                  <span class="viewer">{{ agreeResult.diliNm }}</span>
                  <p>주민번호:</p>
                  <span class="viewer">{{ agreeResult.diliBirth }}</span>
                  <p class="bracket">
                    환자의 <b>({{ agreeResult.rltnTypeNm }}</b>
                    <b class="last">)</b>
                  </p>
                </div>
              </div>
            </div>
            <div class="agree-caption first">
              <p class="paragraph">
                본인은 닥터리퍼의 진료 정보 제공 서비스를 통한 진료의뢰 및 회신,
                그에 따른 연락처 제공에 동의합니다. <br />
                여기서 회신은 <b>{{ form.receive.value }}</b
                >의 진료정보를 진료목적으로 <b>{{ form.userData.hospNm }}</b
                >에 제공하는 것을 말합니다.<br />또한 본인은 진료예약 등 편의를
                위해 <b>{{ form.receive.value }}</b
                >에 본인의 연락처를 공유하는 것에 동의합니다.
              </p>
              <div class="agree-checkbox">
                <!--
              <h5 class="c-title">환자 동의내역:</h5>
              -->
          <!--<div class="checkbox">
                  <div class="item">
                    <img
                      src="@/assets/images/ck_activated.svg"
                      v-if="agreeResult.agreeYn === 'Y'"
                    />
                    <img src="@/assets/images/ck_unActivated.svg" v-else />
                    <p class="name">동의함</p>
                  </div>
                  <div class="item">
                    <img
                      src="@/assets/images/ck_activated.svg"
                      v-if="agreeResult.agreeYn === 'N'"
                    />
                    <img src="@/assets/images/ck_unActivated.svg" v-else />
                    <p class="name">동의하지 않음</p>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="agree-caption">
              <p class="paragraph">
                본인은 진료예약 등 편의를 위해 <b>{{ form.receive.value }}</b
                >에 본인의 연락처를 공유하는 것에 동의합니다.
              </p>
              <div class="agree-checkbox">
                <!-- 
              <h5 class="c-title">환자 동의내역:</h5>
              -->
          <!--<div class="checkbox">
                  <div class="item">
                    <img
                      src="@/assets/images/ck_activated.svg"
                      v-if="agreeResult.optYn1 === 'Y'"
                    />
                    <img src="@/assets/images/ck_unActivated.svg" v-else />
                    <p class="name">동의함</p>
                  </div>
                  <div class="item">
                    <img
                      src="@/assets/images/ck_activated.svg"
                      v-if="agreeResult.optYn1 === 'N'"
                    />
                    <img src="@/assets/images/ck_unActivated.svg" v-else />
                    <p class="name">동의하지 않음</p>
                  </div>
                </div>
              </div>
            </div>-->
          <!--</div>-->
          <div class="agree-info" v-if="agreeResult.agreeYn === 'Y'">
            <div class="agree-info" style="border-bottom: 1px solid #000000">
              <h4>진료정보제공 동의서</h4>
              <div class="agree-caption first">
                <p class="paragraph">
                  환자가 개인정보제공에 동의하였음으로 환자 진료 후 진료내용을
                  의뢰한 병원에 회신 하실 수 있습니다.
                </p>
              </div>
            </div>
          </div>
          <div class="sender-info">
            <div class="article">
              <h5 class="title">발신 병원</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span class="viewer" v-text="form.userData.hospNm"></span>
                </div>
              </div>
            </div>
            <div class="article">
              <h5 class="title">의사명</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span class="viewer" v-text="form.userData.drNm"></span>
                </div>
              </div>
            </div>
            <div class="article">
              <h5 class="title">의뢰일</h5>
              <div class="a-content">
                <div class="input-wrap readonly">
                  <span class="viewer">{{ daySet(form.documentTime) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="document-btn-wrap" v-if="isOwn">
        <div
          class="item iconIn introduce"
          v-if="form.receive.flag"
          @click="viewPr(GET_REFER_DETAIL.param.rcvDrId)"
        >
          <img src="@/assets/images/menuIcon/infoDoc.svg" />
          <p>의사소개</p>
        </div>
        <div class="item iconIn print" @click="hyperPdf()">
          <img src="@/assets/images/menuIcon/print.svg" />
          <p>출력</p>
        </div>
        <div
          class="item iconIn submit modify"
          @click="goModify(GET_REFER_DETAIL.param.referId)"
          v-if="this.form.visitYn.value !== 'Y'"
        >
          <img src="@/assets/images/menuIcon/update.svg" />
          <p>의뢰내용수정</p>
        </div>
      </div>
      <div class="document-btn-wrap" v-else>
        <div
          class="item iconIn introduce"
          v-if="form.receive.flag"
          @click="viewPr(form.userData.drId)"
        >
          <img src="@/assets/images/menuIcon/infoDoc.svg" />
          <p>의사소개</p>
        </div>
        <div class="item iconIn print" @click="hyperPdf()">
          <img src="@/assets/images/menuIcon/print.svg" />
          <p>출력</p>
        </div>
        <div
          class="item active iconIn massive"
          @click="goReplyComment(GET_REFER_DETAIL.param.referId)"
          v-if="this.form.rcvYn.value === 'N' && form.agreeYn.value === 'Y'"
        >
          <img src="@/assets/images/menuIcon/receive.svg" />
          <p>회신등록</p>
        </div>
        <div
          class="item active iconIn massive"
          @click="goReplyCommentUpdate(GET_REFER_DETAIL.param.referId)"
          v-if="this.form.rcvYn.value === 'Y' && form.agreeYn.value === 'Y'"
        >
          <img src="@/assets/images/menuIcon/receive.svg" />
          <p>회신수정</p>
        </div>
        <div
          class="item iconIn temporary"
          @click="doVisit(GET_REFER_DETAIL.param.referId)"
          v-if="this.form.visitYn.value === 'N'"
        >
          <img src="@/assets/images/menuIcon/visit.svg" />
          <p>내원확인</p>
        </div>
        <div class="item iconIn submit" @click="goReplyRequest()">
          <img src="@/assets/images/menuIcon/rerefer.svg" />
          <p>되의뢰</p>
        </div>
      </div>
      <div class="prev-btn" @click="goList()">
        <div class="img-wrap">
          <img src="@/assets/images/prev-btn.svg" />
        </div>
        <p>뒤로가기</p>
      </div>
      <button id="focusControl"></button>
    </div>
    <div class="context" v-if="isMobile">
      <v-container class="mob-container">
        <v-row class="mob-container-row" justify="center">
          <v-col cols="12" class="text-ceter mob-subject">
            <h3>진료의뢰서</h3>
          </v-col>
          <v-col cols="12" class="request-title">
            수신병원
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <span class="viewer" v-text="form.receive.value"></span>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            진료과
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <span class="viewer" v-text="form.subject.value"></span>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            의사명
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <span class="viewer" v-text="form.doctor.value"></span>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            환자명
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <span class="viewer" 
                v-text="
                  this.form.agreeYn.value === 'Y'
                    ? form.name.value
                    : form.name.value[0] + '#' + form.name.value.substr(2)
                "
              ></span>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            주민등록번호
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <span class="viewer" v-text="form.birth.value"></span>
              <span class="separated">-</span>
              <span class="viewer" v-text="form.gender.value"></span>
              <span>XXXXXX</span>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            연락처
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap request-call">
              <span
                class="viewer"
                v-text="
                  this.form.agreeYn.value === 'Y'
                    ? form.phone.value
                    : phoneMasking(form.phone.value)
                "
              ></span>
            </div>
            <div
              class="trans-agree"
            >
              <p
                class="trans-agree-text"
                v-if="!isAgreeTransed"
              >
                {{
                  this.form.agreeYn.value === "Y"
                    ? "환자가 정보제공 동의를 하였습니다. 예약 상담 전화를 기다릴 수 있으므로, 전화해 주시기 바랍니다."
                    : "환자가 정보제공 동의 절차를 거쳐 동의를 하면, 이름과 연락처가 제대로 표시됩니다."
                }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            진단명 또는 주증상
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <span class="viewer" v-text="form.diagnosis.value"></span>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            진단코드
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <span class="viewer" v-text="form.code.value"></span>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            환자 상태 또는 의뢰 내용
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="textarea-wrap">
              <textarea v-model="form.vital.value" readonly no-resize/>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            파일명
          </v-col>
          <v-col cols="12" class="request-content">
            <div
              class="input-wrap request-file"
              @click="download()"
              :class="{
                active: this.form.attachment.filename !== null,
              }"
            >
              <span
                class="file-name"
                v-text="form.attachment.filename"
              ></span>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            회신내용
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="textarea-wrap">
              <textarea
                  v-model="form.rcvConts.value"
                  readonly
                  no-resize
                  v-if="this.isOwn"
                />
                <textarea
                  v-model="form.rcvConts.value"
                  v-else
                  no-resize
                  @keyup.enter="focusControl()"
                  placeholder="회신할 내용을 입력해주세요."
                />
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            파일명
          </v-col>
          <v-col cols="12" class="request-content attachment_rcv">
            <input
              class="hidden file-upload"
              type="file"
              @click.stop
              @change="onFileChange($event)"
              multiple
              hidden
            />
            <div
              class="input-wrap"
              v-if="this.form.rcvAttachment.length === 0"
              @click="attachmentFile()"
            >
              <span class="viewer" v-text="'첨부파일을 선택해주세요'"></span>
            </div>
            <div
              v-else
              class="file-wrap"
              v-for="(data, index) in this.form.rcvAttachment"
              v-bind:key="index"
              @click="download_rcvFile(data)"
              :class="{
                active: data !== null,
              }"
            > 
              <span
                class="file-name"
              >
                <div class="file-span" 
                  v-text="data.name ? data.name : data.fileNm"
                ></div>
                <div
                  class="file-img"
                  v-if="data.fileNm !== undefined"
                >
                  <img
                    :src="
                      'https://www.drrefer.net/api/pic/' + data.fileId
                    "
                    v-if="
                      data.fileNm.substring(
                        data.fileNm.lastIndexOf('.')
                      ) === '.png' ||
                        data.fileNm.substring(
                          data.fileNm.lastIndexOf('.')
                        ) === '.jpg' ||
                        data.fileNm.substring(
                          data.fileNm.lastIndexOf('.')
                        ) === '.jpeg'
                    "
                  />
                </div>
                <div
                  v-if="data.name !== undefined"
                  class="file-img"
                >
                  <img
                    :src="data.url"
                    v-if="
                      data.name.substring(data.name.lastIndexOf('.')) ===
                        '.png' ||
                        data.name.substring(
                          data.name.lastIndexOf('.')
                        ) === '.jpg' ||
                        data.fileNm.substring(
                          data.fileNm.lastIndexOf('.')
                        ) === '.jpeg'
                    "
                  />
                </div>
              </span>
            </div>
            <div>
              <v-btn
                class="request-btn request-btn-file"
                dark
                color="#146F83"
                rounded
                @click="attachmentFile()"
                elevation="0"
              >
                파일첨부
              </v-btn>
              <v-btn
                class="delete request-btn request-btn-file"
                v-if="this.form.rcvAttachment.length !== 0"
                dark
                color="red"
                rounded
                @click="deleteFile()"
                elevation="0"
              >
                삭제
              </v-btn>
              <div class="trans-agree" v-else>
                <p class="trans-agree-text">
                  문서, 사진, 텍스트 등의 파일 첨부가 가능합니다.
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            진료정보제공 동의서
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="content-border">
              <p>
                - 환자가 개인정보제공에 동의하였음으로 환자 진료 후 진료내용을
                  의뢰한 병원에 회신 하실 수 있습니다.
              </p>
            </div>
          </v-col>
          <v-col cols="12" class="request-title">
            발신 병원
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <div class="readonly">
                <span class="viewer" v-text="form.userData.hospNm"></span>
              </div>
            </div>  
          </v-col>
          <v-col cols="12" class="request-title">
            의사명
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <div class="readonly">
                <span class="viewer" v-text="form.userData.drNm"></span>
              </div>
            </div>  
          </v-col>
          <v-col cols="12" class="request-title">
            의뢰일
          </v-col>
          <v-col cols="12" class="request-content">
            <div class="input-wrap">
              <div class="readonly">
                <span class="viewer">{{ daySet(form.documentTime) }}</span>
              </div>
            </div>  
          </v-col>
          <v-col cols="12" class="request-content text-center">
            <div
              v-if="isOwn"
              style="text-center"
            >
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#146F83"
                rounded
                @click="viewPr(GET_REFER_DETAIL.param.rcvDrId)"
                v-if="form.receive.flag"
                elevation="0"
              >
                <img src="@/assets/images/menuIcon/infoDoc.svg" />
                의사소개
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#146F83"
                rounded
                @click="hyperPdf()"
                elevation="0"
              >
                <img src="@/assets/images/menuIcon/print.svg" />
                출력
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#ff7334"
                rounded
                @click="goModify(GET_REFER_DETAIL.param.referId)"
                elevation="0"
                v-if="this.form.visitYn.value !== 'Y'"
              >
                <img src="@/assets/images/menuIcon/update.svg" />
                의뢰내용수정
              </v-btn>
            </div>
            <div
              v-else
              style="text-center"
            >
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#146F83"
                rounded
                @click="viewPr(form.userData.drId)"
                v-if="form.receive.flag"
                elevation="0"
              >
                <img src="@/assets/images/menuIcon/infoDoc.svg" />
                의사소개
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#146F83"
                rounded
                @click="hyperPdf()"
                elevation="0"
              >
                <img src="@/assets/images/menuIcon/print.svg" />
                출력
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#1b6797"
                rounded
                @click="goReplyComment(GET_REFER_DETAIL.param.referId)"
                v-if="this.form.rcvYn.value === 'N' && form.agreeYn.value === 'Y'"
                elevation="0"
              >
                <img src="@/assets/images/menuIcon/receive.svg" />
                회신등록
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#1b6797"
                rounded
                @click="goReplyCommentUpdate(GET_REFER_DETAIL.param.referId)"
                v-if="this.form.rcvYn.value === 'Y' && form.agreeYn.value === 'Y'"
                elevation="0"
              >
                <img src="@/assets/images/menuIcon/receive.svg" />
                회신수정
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#313c49"
                rounded
                @click="doVisit(GET_REFER_DETAIL.param.referId)"
                v-if="this.form.visitYn.value === 'N'"
                elevation="0"
              >
                <img src="@/assets/images/menuIcon/visit.svg" />
                내원확인
              </v-btn>
              <v-btn
                class="request-btn request-btn-end"
                dark
                color="#313c49"
                rounded
                @click="goReplyRequest()"
                elevation="0"
              >
                <img src="@/assets/images/menuIcon/rerefer.svg" />
                되의뢰
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="request-content text-center">
            <v-btn
              class="request-btn-end-circle"
              color="white"
              fab
              @click="goList()"
            >
              <img  class="back" src="@/assets/images/prev-btn.svg" />
            </v-btn>
            <span class="back-text" @click="goList()">
              뒤로가기
            </span>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <app-footer></app-footer> -->
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import axios from "axios";
export default {
  components: {
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      hidedPhone: false,
      form: {
        userData: {
          drId: "",
          drNm: "",
          hospNm: "",
        },
        diagnosis: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진단명을 입력해주세요.",
        },
        vital: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*환자상태를 입력해주세요.",
        },
        receive: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*수신병원을 입력해주세요.",
        },
        doctor: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진료의사를 입력해주세요.",
        },
        subject: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진료과목을 입력해주세요.",
        },
        code: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진단코드를 입력해주세요.",
        },
        name: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "환자명을 입력해주세요.",
        },
        birth: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "생년월일을 입력해주세요.",
        },
        gender: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "성별을 선택해주세요.",
        },
        phone: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*8~20자, 영문+숫자 조합으로 입력해주세요",
        },
        attachment: {
          flag: false,
          title: "병원 로고",
          source: "",
          url: "",
          filename: "",
          isDeleted: 0,
        },
        rcvAttachment: {
          length: 0,
        },
        isPressAgreementRequest: {
          flag: false,
          hasError: false,
          errorTxt: "*동의요청을 확인해주세요.",
        },
        isCheckedAgreement: {
          flag: false,
          hasError: false,
          errorTxt: "*동의여부를 확인해주세요.",
        },
        agreeId: {
          value: "",
        },
        agreeYn: {
          value: "",
        },
        visitYn: {
          value: "",
        },
        rcvDrId: {
          value: "",
        },
        rcvConts: {
          value: "",
        },
        rcvYn: {
          value: "",
        },
        documentTime: "",
      },
      isOwn: false,
      isAgreeTransed: false,
      agreeResult: [],
      popupSet: {},
      setCount: false,
      isMobile: false,
    };
  },
  created() {
    function Mobile(){
	    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
	
    if (Mobile()){// 모바일일 경우
      this.isMobile = true;
    } else {// 모바일 외
      this.isMobile = false;
    }

    
    window.scrollTo(0, 0);
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    //   window.onpopstate = () => {
    //     this.handleGoback();
    //   };
    // }
    this.configureType();
  },
  mounted() {},
  updated() {
    // console.log("====================================");
    // console.log(this.form.agreeYn);
    // console.log(this.form.visitYn);
  },
  computed: {
    ...mapState("basic", ["checkPopup", "isLoading", "checkSearchPopup"]),
    ...mapGetters("request", [
      "GET_AGREE_RESULT",
      "GET_SMS_LIST",
      "GET_REFER_ID",
    ]),
    ...mapGetters("referList", ["GET_REFER_DETAIL"]),
    ...mapGetters("promotion", ["GET_DOCTOR_INFO"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("util", ["GET_PDF_URL"]),
    daySet() {
      return (day) => {
        //console.log("day", day);
        if (day !== undefined) {
          let dano = "";
          dano += day.substr(0, 4);
          dano += "/";
          dano += day.substr(4, 2);
          dano += "/";
          dano += day.substr(6, 2);
          return dano;
        }
      };
    },
    genetateHyphen() {
      return (numData, isBirth) => {
        /*console.log("가져온 번호들", numData);
        console.log("팩스인가?", isBirth);*/
        const number = numData.toString().replace(/[^0-9]/g, "");
        let generatedNum = "";
        if (isBirth) {
          if (number.length < 5) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4, 2);
            generatedNum += "-";
            generatedNum += number.substr(6);
          }
          this.form.birth.value = generatedNum;
        } else {
          if (number.length < 4) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 3);
            generatedNum += "-";
            generatedNum += number.substr(6);
          } else {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 4);
            generatedNum += "-";
            generatedNum += number.substr(7);
          }
          this.form.phone.value = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("util", ["BRING_PDF_URL_ON_REFER", "BRING_PDF"]),
    ...mapActions("referList", ["REFER_DETAIL"]),
    ...mapActions("request", ["VISIT_CHECK"]),
    ...mapMutations("request", ["SET_REPLY_STATUS", "SET_REFER_ID"]),
    focusControl() {
      const focusControl = document.getElementById("focusControl");
      //console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    deleteFile() {
      this.form.rcvAttachment = { length: 0 };
      document.querySelector("input.file-upload").value = "";
    },
    attachmentFile() {
      const parentNode = document.querySelector(".attachment_rcv");
      //console.log("parentNode", parentNode);
      parentNode.querySelector("input.file-upload").click();
    },
    onFileChange(event) {
      /*console.log(event.target.files.length);
      console.log("File : ", event.target.files);*/
      var fileArray = {};

      for (var i = 0; i < event.target.files.length; i++) {
        /*console.log("가져올 파일명: ", event.target.files[i].name);
        console.log(
          "가져올 파일의 URL",
          URL.createObjectURL(event.target.files[i])
        );*/
        fileArray[i] = event.target.files[i];
        fileArray[i].url = URL.createObjectURL(event.target.files[i]);
        //console.log(fileArray);
      }

      this.form.rcvAttachment = event.target.files;
    },
    goList() {
      // this.$router.push("/v/request/list");
      // this.$router.push("/v/mypage/request/0");
      this.$router.go(-1);
    },
    phoneFomatter(number) {
      /*console.log("정보", number);
      console.log("정보렝", number.length);*/
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        //console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    phoneMasking(str) {
      let originStr = str;
      let phoneStr;
      let maskingStr;
      if (this.checkNull(originStr) == true) {
        return originStr;
      }
      if (originStr.toString().split("-").length != 3) {
        // 1) -가 없는 경우
        phoneStr =
          originStr.length < 11
            ? originStr.match(/\d{10}/gi)
            : originStr.match(/\d{11}/gi);
        if (this.checkNull(phoneStr) == true) {
          return originStr;
        }
        if (originStr.length < 11) {
          // 1.1) 0110000000
          maskingStr = originStr
            .toString()
            .replace(
              phoneStr,
              phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi, "$1###$3")
            );
        } else {
          // 1.2) 01000000000
          maskingStr = originStr
            .toString()
            .replace(
              phoneStr,
              phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi, "$1####$3")
            );
        }
      } else {
        // 2) -가 있는 경우
        phoneStr = originStr.match(/\d{2,3}-\d{3,4}-\d{4}/gi);
        if (this.checkNull(phoneStr) == true) {
          return originStr;
        }
        if (/-[0-9]{3}-/.test(phoneStr)) {
          // 2.1) 00-000-0000
          maskingStr = originStr
            .toString()
            .replace(
              phoneStr,
              phoneStr.toString().replace(/-[0-9]{3}-/g, "-###-")
            );
        } else if (/-[0-9]{4}-/.test(phoneStr)) {
          // 2.2) 00-0000-0000
          maskingStr = originStr
            .toString()
            .replace(
              phoneStr,
              phoneStr.toString().replace(/-[0-9]{4}-/g, "-####-")
            );
        }
      }
      return maskingStr;
    },
    checkNull(str) {
      if (typeof str == "undefined" || str == null || str == "") {
        return true;
      } else {
        return false;
      }
    },
    handleGoback() {
      if (this.setCount) {
        this.$router.push("/v/mypage/request/0").catch(()=>{});
      } else {
        history.go(1);
      }
      this.setCount = true;
      // 클로즈 함수 구현
    },
    goModify(referUrl) {
      //console.log("getReferUrl:", referUrl);
      this.SET_REFER_ID(referUrl);
      this.$router.push("/v/request/requestDocument").catch(()=>{});
    },
    async goReplyComment(referId) {
      var rcvAttachment = this.form.rcvAttachment;
      var replyComment = this.form.rcvConts;
      this.$refs.appheader.callReplyComment(
        referId,
        replyComment,
        "Add",
        rcvAttachment
      );
    },
    async goReplyCommentUpdate(referId) {
      var rcvAttachment = this.form.rcvAttachment;
      var replyComment = this.form.rcvConts;
      //console.log(rcvAttachment);
      this.$refs.appheader.callReplyComment(
        referId,
        replyComment,
        "Update",
        rcvAttachment
      );
    },
    async doVisit(referId) {
      /*console.log("asd");
      var rcvAttachment = this.form.rcvAttachment;
      var replyComment = this.form.rcvConts;
      this.$refs.appheader.callVisit(
        referId,
        replyComment,
        "Add",
        rcvAttachment
      );*/
      ////////////////////////////////////////////////////확인필요
      //console.log("referId", referId);

      if(referId === null 
        || referId === undefined)
        referId = "";

      var referIdBoo = /^[0-9]{8,14}$/;

      if(!referIdBoo.test(referId)){
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = " 진료의뢰서 변경을 실패하였습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }
      
      const payload = {
        referId: referId,
      };

      try {
        await this.VISIT_CHECK(payload).then(() => {
          this.SET_POPUP(true);
          this.popupSet.title = "내원확인";
          this.popupSet.content = "내원확인이 완료되었습니다.";
          this.popupSet.popType = "custom";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.destination = true;
          this.popupSet.nextLink = "/v/mypage/request/0";
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "내원확인을 처리할 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    goReplyRequest() {
      this.SET_REFER_ID(null);
      //console.log(this.GET_REFER_DETAIL.param);
      const status = {
        receive: this.form.userData.hospNm,
        doctor: this.form.userData.drNm,
        drId: this.form.userData.drId,
        referId: this.GET_REFER_DETAIL.param.referId,
        proObj: this.GET_REFER_DETAIL.param.sndProObj,
        reply: "Y",
      };
      this.SET_REPLY_STATUS(status);
      this.$router.push("/v/request/requestDocument").catch(()=>{});
    },
    viewPr(drId) {
      /**  
      this.SET_DRID_PARAM(drId);
      this.$router.push("/v/pr/view");
      */
      let routeData = this.$router.resolve({
        name: "PrView",
        params: { code: drId },
      });
      window.open(routeData.href, "_blank");
    },
    download() {
      if (
        this.GET_REFER_DETAIL.param.referAttchNm ===
          this.form.attachment.filename &&
        this.GET_REFER_DETAIL.param.referAttchNm !== ""
      ) {
        //console.log(this.GET_REFER_DETAIL.param.referAttchId);
        /*window.open(
          "https://www.drrefer.net/api/refer/" +
            this.GET_REFER_DETAIL.param.referAttchId,
          "_blank"
        );*/
        var url =
          "https://www.drrefer.net/api/refer/" +
          this.GET_REFER_DETAIL.param.referAttchId;
        var headers = {
          Authorization: "Bearer " + localStorage.getItem("token"),
        };
        var config = {
          responseType: "blob",
          headers: headers,
        };
        axios.get(url, config).then(function(response) {
          var blob = response.data;
          var u = URL.createObjectURL(blob);
          window.open(u, "_blank");
        });
      }
    },
    download_rcvFile(data) {
      /*console.log(data);
      console.log(data.url);*/
      if (data.url === undefined) {
        var url = "https://www.drrefer.net/api/refer/" + data.fileId;
        var headers = {
          Authorization: "Bearer " + localStorage.getItem("token"),
        };
        var config = {
          responseType: "blob",
          headers: headers,
        };
        axios.get(url, config).then(function(response) {
          var blob = response.data;
          var u = URL.createObjectURL(blob);
          window.open(u, "_blank");
        });
      } else {
        window.open(data.url, "_blank");
      }
    },
    async hyperPdf() {
      await this.callPdf();
      await this.fileCall();
    },
    async fileCall() {
      try {
        await this.BRING_PDF(this.GET_PDF_URL.fileName).then(() => {
          //console.log(this.GET_PDF_URL);
          const blob = new Blob([this.GET_PDF_URL], {
            type: "application/pdf",
          });
          const result = URL.createObjectURL(blob);
          //console.log("찾기", result);
          const url = "https://www.drrefer.net/pdf/" + result;
          //console.log("called_Pdf_url", url);

          window.open(result, "_blank");
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async callPdf() {
      try {
        await this.BRING_PDF_URL_ON_REFER(
          this.GET_REFER_DETAIL.param.referId
        ).then(() => {
          //console.log("댐", this.GET_PDF_URL);
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "PDF를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async bringDetail() {
      try {
        await this.REFER_DETAIL(this.GET_REFER_ID).then(() => {
         //console.log("디테일 이후", this.GET_REFER_DETAIL);

          this.form.rcvAttachment = this.GET_REFER_DETAIL.list;

          if (this.GET_REFER_DETAIL.param.rcvYn === "Y") {
            this.form.rcvYn.value = "Y";
          } else {
            this.form.rcvYn.value = "N";
          }
          if (this.GET_REFER_DETAIL.param.visitYn === "Y") {
            this.form.visitYn.value = "Y";
          } else {
            this.form.visitYn.value = "N";
          }
          if (this.GET_REFER_DETAIL.param.agreeYn === "Y") {
            this.form.agreeYn.value = "Y";
          } else {
            this.form.agreeYn.value = "N";
          }
          if (
            this.GET_REFER_DETAIL.param.rcvDrId !== null &&
            this.GET_REFER_DETAIL.param.rcvDrId !== ""
          ) {
            this.form.rcvDrId.value = this.GET_REFER_DETAIL.param.rcvDrId;
          }
          if (
            this.GET_REFER_DETAIL.param.sndDrId !== null &&
            this.GET_REFER_DETAIL.param.sndDrId !== ""
          ) {
            this.form.userData.drId = this.GET_REFER_DETAIL.param.sndDrId;
          }
          if (
            this.GET_REFER_DETAIL.param.sndDrNm !== null &&
            this.GET_REFER_DETAIL.param.sndDrNm !== ""
          ) {
            this.form.userData.drNm = this.GET_REFER_DETAIL.param.sndDrNm;
          }
          if (
            this.GET_REFER_DETAIL.param.sndHospNm !== null &&
            this.GET_REFER_DETAIL.param.sndHospNm !== ""
          ) {
            this.form.userData.hospNm = this.GET_REFER_DETAIL.param.sndHospNm;
          }
          if (
            this.GET_REFER_DETAIL.param.rcvHospNm !== null &&
            this.GET_REFER_DETAIL.param.rcvHospNm !== ""
          ) {
            this.form.receive.flag = true;
            this.form.receive.value = this.GET_REFER_DETAIL.param.rcvHospNm;
          }
          if (
            this.GET_REFER_DETAIL.param.rcvDrNm !== null &&
            this.GET_REFER_DETAIL.param.rcvDrNm !== ""
          ) {
            this.form.doctor.flag = true;
            this.form.doctor.value = this.GET_REFER_DETAIL.param.rcvDrNm;
          }
          if (
            this.GET_REFER_DETAIL.param.diagCd !== null &&
            this.GET_REFER_DETAIL.param.diagCd !== ""
          ) {
            this.form.code.flag = true;
            this.form.code.value = this.GET_REFER_DETAIL.param.diagCd;
          }
          if (
            this.GET_REFER_DETAIL.param.rcvParts !== null &&
            this.GET_REFER_DETAIL.param.rcvParts !== ""
          ) {
            this.form.subject.flag = true;
            this.form.subject.value = this.GET_REFER_DETAIL.param.rcvParts;
          }
          if (
            this.GET_REFER_DETAIL.param.patNm !== null &&
            this.GET_REFER_DETAIL.param.patNm !== ""
          ) {
            this.form.name.flag = true;
            this.form.name.value = this.GET_REFER_DETAIL.param.patNm;
          }
          if (
            this.GET_REFER_DETAIL.param.referDt !== null &&
            this.GET_REFER_DETAIL.param.referDt !== ""
          ) {
            this.form.documentTime = this.GET_REFER_DETAIL.param.referDt;
          }
          if (
            this.GET_REFER_DETAIL.param.patBirth !== null &&
            this.GET_REFER_DETAIL.param.patBirth !== ""
          ) {
            this.form.birth.flag = true;
            this.form.birth.value = this.GET_REFER_DETAIL.param.patBirth.substr(
              0,
              6
            );
            this.form.gender.flag = true;
            this.form.gender.value = this.GET_REFER_DETAIL.param.patBirth.split(
              "-"
            )[1];
          }
          if (
            this.GET_REFER_DETAIL.param.agreeYn !== null &&
            this.GET_REFER_DETAIL.param.agreeYn !== ""
          ) {
            const agreeData = {
              agreeYn: this.GET_REFER_DETAIL.param.agreeYn,
              diliNm: this.GET_REFER_DETAIL.param.diliNm,
              diliBirth: this.GET_REFER_DETAIL.param.diliBirth,
              rltnTypeNm: this.GET_REFER_DETAIL.param.rltnTypeNm,
              optYn1: this.GET_REFER_DETAIL.param.optYn1,
              optYn2: this.GET_REFER_DETAIL.param.optYn2,
              optYn3: this.GET_REFER_DETAIL.param.optYn3,
            };
            this.agreeResult = agreeData;
            if (
              agreeData.optYn1 === "N" &&
              this.form.userData.drNm !== localStorage.getItem("drNm")
            ) {
              this.hidedPhone = true;
            }
          }
          if (
            this.GET_REFER_DETAIL.param.patTelno !== null &&
            this.GET_REFER_DETAIL.param.patTelno !== ""
          ) {
            this.form.phone.flag = true;
            this.form.phone.value = this.phoneFomatter(
              this.GET_REFER_DETAIL.param.patTelno
            );
          }
          if (
            this.GET_REFER_DETAIL.param.agreeId !== null &&
            this.GET_REFER_DETAIL.param.agreeId !== ""
          ) {
            this.form.agreeId.flag = true;
            this.form.agreeId.value = this.GET_REFER_DETAIL.param.agreeId;
          }
          if (
            this.GET_REFER_DETAIL.param.agreeYn !== null &&
            this.GET_REFER_DETAIL.param.agreeYn !== ""
          ) {
            this.form.isCheckedAgreement.flag = true;
          }

          if (
            this.GET_REFER_DETAIL.param.diagNm !== null &&
            this.GET_REFER_DETAIL.param.diagNm !== ""
          ) {
            this.form.diagnosis.flag = true;
            this.form.diagnosis.value = this.GET_REFER_DETAIL.param.diagNm;
          }
          if (
            this.GET_REFER_DETAIL.param.referConts !== null &&
            this.GET_REFER_DETAIL.param.referConts !== ""
          ) {
            this.form.vital.flag = true;
            this.form.vital.value = this.GET_REFER_DETAIL.param.referConts;
          }
          if (
            this.GET_REFER_DETAIL.param.referAttchId !== null &&
            this.GET_REFER_DETAIL.param.referAttchId !== ""
          ) {
            this.form.attachment.flag = true;
            this.form.attachment.source = this.GET_REFER_DETAIL.param.referAttchId;
          }
          if (
            this.GET_REFER_DETAIL.param.referAttchNm !== null &&
            this.GET_REFER_DETAIL.param.referAttchNm !== ""
          ) {
            this.form.attachment.filename = this.GET_REFER_DETAIL.param.referAttchNm;
          }
          if (
            this.GET_REFER_DETAIL.param.rcvConts !== null &&
            this.GET_REFER_DETAIL.param.rcvConts !== ""
          ) {
            this.form.rcvConts.value = this.GET_REFER_DETAIL.param.rcvConts;
          }
          this.isOwnRefer();
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 상세내용을 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    isOwnRefer() {
      if (this.form.userData.drId === localStorage.getItem("drId")) {
        this.isOwn = true;
      }
    },
    configureType() {
      if (this.GET_REFER_ID !== undefined && this.GET_REFER_ID !== null) {
        this.bringDetail();
      }
    },
  },
  watch: {},
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/requestform";
@import "@/assets/scss/requestView";

input, textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
